<template>
  <div>

    <b-row>

      <b-col
        xl="4" cols="12"
      >
        <b-card>
          <b-card-header>

          <b-card-title
          >
            <p
              class="h5"
            >
              Resumo da Assinatura
            </p>
          </b-card-title>
            
          <b-card-text>
            <b-link
              :to="{ name: 'financeiro-assinatura-planos'}"
            >
              <feather-icon
                  size="16"
                  icon="EditIcon"
                  class="text-dark"
                />
              </b-link>
          </b-card-text>

          </b-card-header>
          <b-card-body>
            <p class="small p-0 m-0">Plano</p>
            <p class="h6 p-0 mt-0">{{ planoSelecionado.nome }}</p>
            <p class="small p-0 m-0">Valor</p>
            <p class="h6 p-0 m-0">{{ (planoSelecionado.valor / 100) | currency }}</p>
          </b-card-body>
         
        </b-card>
      </b-col>

      <b-col 
        xl="5" cols="12"
      >
        <b-card 
          class="d-flex justify-content-center align-items-center"
        >

          <b-card-body>


            <b-col
              cols="12"
              style="background: #ff7300;padding: 40px 30px 40px 30px;color:#FFF;min-width: 320px;border-radius: 10px;"
            >
            
              <div>
                <b-img
                  :src="require('@/assets/images/bandeiras/'+cartaoBandeiraImagem)"
                  width="50"
                  style="position: absolute;right:25px;"
                >
                </b-img>
              </div>

              <div>
                <p 
                  class="mb-0" 
                  style="font-size: 10px;font-weight: bold;"
                >
                  Nome
                </p>

                <span 
                  style="font-size: 12px;text-transform: uppercase;"
                >
                  {{ previaCartaoNome }}
                </span>

              </div>

              <div>
                <p 
                  class="mb-0" 
                  style="font-size: 10px;font-weight: bold;"
                >
                  Número do Cartão
                </p>

                <span 
                  style="font-size: 12px;"
                >
                  {{ previaCartaoNumero }}
                </span>
                
              </div>

              <div>
                
                <b-row>
                  <b-col
                    cols="6"
                  >
                    <p 
                      class="mb-0" 
                      style="font-size: 10px;font-weight: bold;"
                    >
                      Validade
                    </p>

                    <span 
                      style="font-size: 12px;"
                    >
                      {{ previaCartaoValidade }}
                    </span>
                  </b-col>

                  <b-col
                    cols="6"
                  >
                    <p 
                      class="mb-0" 
                      style="font-size: 10px;font-weight: bold;"
                    >
                      CVV
                    </p>

                    <span 
                      style="font-size: 12px;"
                    >
                      {{ previaCartaoCvv }}
                    </span>
                  </b-col>
                </b-row>
                
              </div>

            </b-col>

            <b-col
              cols="12"
            >

            <validation-observer
              #default="{invalid}"
              ref="negociacaoForm"
            >

              <!-- Form -->
              <b-form
                ref="negociacaoForms"
                class="mt-2"
                @submit.prevent="salvarCartao"
              >

                <b-row>

                  <b-col
                    class="p-0"
                  >

                    <b-row>
                      
                      <b-col
                        cols="12"
                      >
                        <!-- Nome -->
                        <b-form-group
                          label="Nome"
                          label-for="cartaoNome"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="cartaoNome"
                            rules="required"
                          >

                            <b-form-input
                              id="cartaoNome"
                              v-model="cartaoNome"
                              :state="errors.length > 0 ? false:null"
                              placeholder="Nome do titular"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <!-- Nome -->
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <!-- Número -->
                        <b-form-group
                          label="Número do Cartão"
                          label-for="cartaoNumero"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="cartaoNumero"
                            rules="required"
                          >

                            <b-form-input
                              id="cartaoNumero"
                              v-model="cartaoNumero"
                              :state="errors.length > 0 ? false:null"
                              placeholder="Número do cartão"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <!-- Número -->
                      </b-col>

                    </b-row>

                    <b-row>

                     <b-col
                      cols="6"
                     >

                       <!-- Validade -->
                       <b-form-group
                        label="Validade"
                        label-for="cartaoValidade"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="cartaoValidade"
                          rules="required"
                        >

                          <the-mask
                            id="cartaoValidade"
                            v-model="cartaoValidade"
                            :mask="['##/##']"
                            :masked="true"
                            :state="errors.length > 0 ? false:null"
                            placeholder="MM/AA"
                            class="form-control"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <!-- Validade -->

                     </b-col>

                     <b-col
                      cols="6"
                     >

                       <!-- Cvv -->
                       <b-form-group
                        label="CVV"
                        label-for="cartaoCvv"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="cartaoCvv"
                          rules="required"
                        >

                          <the-mask
                            id="cartaoCvv"
                            v-model="cartaoCvv"
                            :mask="['####']"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Código"
                            class="form-control"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <!-- Cvv -->

                     </b-col>


                    </b-row>

                  </b-col>

                </b-row>

                <b-row>

                  <b-button
                    type="submit"
                    variant="dark"
                    style="background-color: #000 !important;"
                    class="mt-1 ml-auto"
                    :disabled="invalid || respostaApi"
                  >
                    Realizar Pagamento
                    <b-spinner
                      v-if="respostaApi"
                      small
                      label="Carregando"
                    />
                  </b-button>

                </b-row>
                

              </b-form>
            </validation-observer>

              
            </b-col>
            

          </b-card-body>

            
        </b-card>
      </b-col>
    </b-row>

    <!-- Modal Completar Endereço -->

  <b-modal
      id="modal-completar-endereco"
      ref="modalCompletarEndereco"
      ok-only
      ok-title="Cadastrar Endereço"
      centered
      no-close-on-backdrop
      size="md"
      title="Atualização Cadastral"
      @ok="cadastrarEndereco"
      @hide="redirecionarPlanos"
    >

      <template v-slot:modal-footer>
        <b-button
          block
          variant="primary"
          :disabled="invalid || respostaApi"
          @click="cadastrarEndereco"
        >
          Cadastrar Endereço
          <b-spinner
            v-if="respostaApi"
            small
            label="Carregando"
          />
        </b-button>
      </template>

      <h4>Complete o seu endereço</h4>

      <b-alert
        variant="primary"
        show
        class="mt-1"
      >
        <div class="alert-body">
          <span><strong>Atualize os seus dados!</strong> Para continuar com a assinatura complete o seu cadastro.</span>
        </div>
      </b-alert>


      <validation-observer
        #default="{invalid}"
        ref="enderecoForm"
      >

        <!-- Form -->
        <b-form
          ref="enderecoForms"
          class="mt-2"
          @submit.prevent="negociarHitCadastrar"
        >

          <b-row>

            <b-col
              cols="4"
            >

              <!-- Cep -->
              <b-form-group
                label="CEP"
                label-for="enderecoCepLabel"
              >
                <validation-provider
                  #default="{ errors }"
                  name="enderecoCep"
                  rules="required"
                >
                  <the-mask
                    id="enderecoCep"
                    v-model="enderecoCep"
                    :mask="['#####-###']"
                    :masked="false"
                    :state="errors.length > 0 ? false:null"
                    placeholder="00000-000"
                    class="form-control"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Cep -->

            </b-col>

            <b-col>

              <!-- Endereço -->
              <b-form-group
                label="Endereço"
                label-for="enderecoLogradouroLabel"
              >
                <validation-provider
                  #default="{ errors }"
                  name="enderecoLogradouro"
                  rules="required"
                >

                  <b-form-input
                    id="enderecoLogradouro"
                    v-model="enderecoLogradouro"
                    name="enderecoLogradouro"
                    :state="errors.length > 0 ? false:null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Endereço -->

            </b-col>

          </b-row>

          <b-row>

            <b-col
              cols="4"
            >

              <!-- Número -->
              <b-form-group
                label="Número"
                label-for="enderecoNumeroLabel"
              >
                <validation-provider
                  #default="{ errors }"
                  name="enderecoNumero"
                  rules="required"
                >

                  <b-form-input
                    id="enderecoNumero"
                    v-model="enderecoNumero"
                    name="enderecoNumero"
                    :state="errors.length > 0 ? false:null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Número -->

            </b-col>

            <b-col>

              <!-- Bairro -->
              <b-form-group
                label="Bairro"
                label-for="enderecoBairroLabel"
              >
                <validation-provider
                  #default="{ errors }"
                  name="enderecoBairro"
                  rules="required"
                >

                  <b-form-input
                    id="enderecoBairro"
                    v-model="enderecoBairro"
                    name="enderecoBairro"
                    :state="errors.length > 0 ? false:null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Bairro -->

            </b-col>

          </b-row>

          <b-row>

            <b-col
              cols="12"
            >

              <!-- Complemento -->
              <b-form-group
                label="Complemento"
                label-for="enderecoComplementoLabel"
              >
                <validation-provider
                  #default="{ errors }"
                  name="enderecoComplemento"
                >

                  <b-form-input
                    id="enderecoComplemento"
                    v-model="enderecoComplemento"
                    name="enderecoComplemento"
                    :state="errors.length > 0 ? false:null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Número -->

            </b-col>

          </b-row>

          <b-row>

            <b-col
              cols="8"
            >

              <!-- Cidade -->
              <b-form-group
                label="Cidade"
                label-for="enderecoCidadeLabel"
              >
                <validation-provider
                  #default="{ errors }"
                  name="enderecoCidade"
                  rules="required"
                >

                  <b-form-input
                    id="enderecoCidade"
                    v-model="enderecoCidade"
                    name="enderecoCidade"
                    :state="errors.length > 0 ? false:null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Cidade -->

            </b-col>

            <b-col>

              <!-- Estado -->
              <b-form-group
                label="Estado"
                label-for="enderecoEstadoLabel"
              >
                <validation-provider
                  #default="{ errors }"
                  name="enderecoEstado"
                  rules="required"
                >

                  <b-form-input
                    id="enderecoEstado"
                    v-model="enderecoEstado"
                    name="enderecoEstado"
                    :state="errors.length > 0 ? false:null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Estado -->

            </b-col>

          </b-row>

        </b-form>
      </validation-observer>

    </b-modal>

  </div>

</template>

<script>
import Vue from 'vue'

// import EfiJs from 'payment-token-efi'

import {
  BAlert, BRow, BImg, BCol, BCard, BCardHeader, BCardBody, BCardFooter, BForm, BFormGroup, BInputGroup, BFormInput, BCardText, BCardTitle, BInputGroupPrepend, BButton, BBadge, BLink, BSpinner, BModal,
} from 'bootstrap-vue'

import router from '@/router'

import VueCreditCard from '@fracto/vue-credit-card';

import { Validator, ValidationProvider, ValidationObserver, localize, extend } from 'vee-validate'

import { required, email } from '@validations'

import { TheMask } from 'vue-the-mask'

import Cleave from 'cleave.js';

import useJwt from '@/auth/jwt/useJwt'

import VueCurrencyFilter from 'vue-currency-filter'

Vue.use(VueCurrencyFilter, {
  symbol: 'R$',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true,
})

localize({
  en: {
    messages: {
      // generic rule messages...
    },
    fields: {
      cartaoNome: {
        required: 'O nome é obrigatório.',
      },
      cartaoNumero: {
        required: 'O número do cartão é obrigatório.',
      },
      cartaoValidade: {
        required: 'Validade é obrigatória.',
      },
      cartaoCvv: {
        required: 'CVV é obrigatório.',
      },
      enderecoCep: {
        required: 'CEP é obrigatório.',
      },
      enderecoLogradouro: {
        required: 'Logradouro é obrigatório.',
      },
      enderecoNumero: {
        required: 'Número é obrigatório.',
      },
      enderecoBairro: {
        required: 'Bairro é obrigatório.',
      },
      enderecoCidade: {
        required: 'Cidade é obrigatória.',
      },
      enderecoEstado: {
        required: 'Estado é obrigatório.',
      },
    },
  },
})

export default {
  components: {
    BAlert,
    BRow,
    BImg,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BCardText,
    BCardTitle,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BBadge,
    BLink,
    BSpinner,
    BModal,
    VueCreditCard,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    TheMask,
    VueCurrencyFilter,
  },
  data() {
    return {
      invalid: false, 
      previaCartaoNome: 'Seu Nome',
      previaCartaoNumero: 'XXXX XXXX XXXX XXXX',
      previaCartaoValidade: 'MM/AA',
      previaCartaoCvv: '123',
      cartaoBandeira: '',
      cartaoBandeiraImagem: 'sem-bandeira.png',
      cartaoNome: '',
      cartaoNumero: '',
      cartaoValidade: '',
      cartaoCvv: '',
      cartaoPaymentToken: '',
      cartaoFinalCartao: '',
      card: {
        brand: '',
        number: '',
        cvv: '',
        expirationMonth: '',
        expirationYear: '',
        reuse: false,
      },
      consulta: null,
      respostaApi: false,
      enderecoCep: '',
      enderecoLogradouro: '',
      enderecoNumero: '',
      enderecoBairro: '',
      enderecoComplemento: '',
      enderecoCidade: '',
      enderecoEstado: '',
      planos: [],
      planoId: router.currentRoute.params.id,
      planoSelecionado: [],
    }
  },
  watch: {
    cartaoNome: function(newValue) {
      if (newValue === '') {
        this.previaCartaoNome = 'SEU NOME';
      } else {
        this.previaCartaoNome = newValue;
      }
    },
    cartaoNumero: function(newValue) {
      if (newValue === '') {
        this.previaCartaoNumero = 'XXXX XXXX XXXX XXXX';
        this.cartaoBandeira = '';
      } else {
        this.previaCartaoNumero = newValue;
        this.cartaoBandeira = this.getCardFlag(newValue);
      }
    },
    cartaoValidade: function(newValue) {
      if (newValue === '') {
        this.previaCartaoValidade = 'MM/AA';
      } else {
        this.previaCartaoValidade = newValue;
      }
    },
    cartaoCvv: function(newValue) {
      if (newValue === '') {
        this.previaCartaoCvv = '123';
      } else {
        this.previaCartaoCvv = newValue;
      }
    },
    enderecoCep(novoValor, valorAntigo) {
      if (novoValor.length === 8) {
        fetch(`https://viacep.com.br/ws/${this.enderecoCep}/json/`)
          .then(response => response.json())
          .then(data => {
            this.enderecoLogradouro = data.logradouro;
            this.enderecoBairro = data.bairro;
            this.enderecoCidade = data.localidade;
            this.enderecoEstado = data.uf;
          })
          .catch(error => console.error(error));
      }
    },
    
  },
  mounted() {

    // Verificar Endereço
    const usuarioData = JSON.parse(localStorage.getItem('usuarioData'))
    const completarCadastro = usuarioData.completarCadastro

    if (completarCadastro === true) {
      this.modalCompletarEndereco()
    }

    // Verificar Plano Selecionado

    useJwt.assinaturaPlanosLista({
        // page: this.page,
        // size: this.size,
        // sort: this.sort,
      })
    .then(response => {

      this.planos = response.data.content

      //console.log(this.planos)
    
    })
    .catch(error => {
      console.log(error)
    })
    .finally(() => {

      let planoSelecionado = null;

      for (let i = 0; i < this.planos.length; i++) {
        if (this.planos[i].id == this.planoId) {
          planoSelecionado = this.planos[i];
          break;
        }
      }

      if (planoSelecionado) {
        
        this.planoSelecionado = {
          credito: planoSelecionado.credito,
          nome: planoSelecionado.nome,
          valor: planoSelecionado.valor
        }

      } else {

        console.log("Plano não encontrado com o ID:", this.planoId);

      }

    })

    // Verificar Endereço


    new Cleave('#cartaoNumero', {
      creditCard: true,
      delimiter: ' ',
    })

    // const EfiJs = require('payment-token-efi');

    // const identificadorDeConta = 'e81311a713d956637d79d1ebd0fc3227';
    // const url = 'https://api.gerencianet.com.br/v1/cdn/';
    // const script = document.createElement('script');
    // const v = parseInt(Math.random() * 1000000);

    // script.type = 'text/javascript';
    // script.src = url + identificadorDeConta + '/' + v;
    // script.async = false;
    // script.id = identificadorDeConta;

    // script.addEventListener('load', () => {
    //   $gn.ready((checkout) => {
    //     this.consulta = checkout
    //   });
    // });

    // if (!document.getElementById(identificadorDeConta)) {
    //   document.getElementsByTagName('head')[0].appendChild(script);
    // }

    // window.$gn = {
    //   validForm: true,
    //   processed: false,
    //   done: {},
    //   ready: function (fn) {
    //     window.$gn.done = fn;
    //   },
    // };

  },
  methods: {

    // Modal Completar Cadastro

    modalCompletarEndereco() {
      this.$refs.modalCompletarEndereco.show()
    },

    redirecionarPlanos() {
      this.$router.push({ name: 'financeiro-assinatura-planos' });
    },

    // Modal Completar Cadastro

    // Consultar CEP

    consultarCEP() {
      fetch(`https://viacep.com.br/ws/${this.cep}/json/`)
        .then(response => response.json())
        .then(data => {
          this.enderecoLogradouro = data.logradouro;
          this.enderecoBairro = data.bairro;
          this.enderecoCidade = data.localidade;
          this.enderecoEstado = data.uf;
        })
        .catch(error => console.error(error));
    },
    
    // Consultar CEP

    // Cadastrar Endereço

    cadastrarEndereco() {

      if(this.enderecoCep.length < 8) {
        this.$swal({
              title: 'Erro!',
              text: 'Cep invalido. Tente novamente.',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
      } else {

      this.$refs.enderecoForm.validate().then(success => {
        if (success) {
          
          this.respostaApi = true

          useJwt.compositorEndereco({
            logradouro: this.enderecoLogradouro,
            numero: this.enderecoNumero,
            bairro: this.enderecoBairro,
            cidade: this.enderecoCidade,
            estado: this.enderecoEstado,
            complemento: this.enderecoComplemento,
            cep: this.enderecoCep,
          })
          .then(response => {

            const usuarioData = JSON.parse(localStorage.getItem('usuarioData'));
            usuarioData.completarCadastro = false;
            localStorage.setItem('usuarioData', JSON.stringify(usuarioData));

            this.$swal({
              title: 'Endereço cadastrado!',
              text: 'Seu cadastrado foi atualizado.',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.isConfirmed) {
                this.$refs.modalCompletarEndereco.hide()
              } else {
                this.$refs.modalCompletarEndereco.hide()
              }
            })

          })
          .catch(error => {

            this.$swal({
              title: 'Erro!',
              text: 'Ocorreu um erro. Tente novamente.',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })

          })
          .finally(() => {
            this.respostaApi = false
          })

        }

      })
    }
    },

    // Cadastrar Endereço

    getCardFlag(cardnumbers) {
      let cardnumber = cardnumbers.replace(/[^0-9]+/g, '');
      const cards = {
        visa: /^4[0-9]{12}(?:[0-9]{3})/,
        mastercard: /^5[1-5][0-9]{14}/,
        diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
        amex: /^3[47][0-9]{13}/,
        discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
        hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
        elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
        jcb: /^(?:2131|1800|35\d{3})\d{11}/,
        aura: /^(5078\d{2})(\d{2})(\d{11})$/
      };
      for (let flag in cards) {
        if (cards[flag].test(cardnumber)) {
          this.card.brand = flag;
          this.cartaoBandeiraImagem = flag+'.png'
          //console.log(this.card.brand)
          return;
        }
      }
      this.card.brand = null;
      this.cartaoBandeiraImagem = 'sem-bandeira.png'
    },

    salvarCartao() {

      this.card.number = this.cartaoNumero
      this.card.cvv = this.cartaoCvv

      let [expiration_month, expiration_year] = this.cartaoValidade.split("/").map(str => parseInt(str, 10))

      // Converter o valor do ano de AA para AAAA
      if (expiration_year < 100) {
        expiration_year = 2000 + expiration_year
      }

      this.card.expirationMonth = String(expiration_month).padStart(2, '0')
      this.card.expirationYear = String(expiration_year)

      const year = new Date().getFullYear();

      if (this.card.expirationYear < year) {
        // O ano de validade é menor que o ano atual
        
        this.$swal({
          title: 'Ops! Aconteceu um erro.',
          text: 'Data de validade inválida.',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })

        return false;
      }

      console.log(this.card)

      this.respostaApi = true

      EfiJs.CreditCard
      .setAccount('e81311a713d956637d79d1ebd0fc3227')
      .setEnvironment('production') // 'production' or 'sandbox'
      .setCreditCardData(this.card)
      .getPaymentToken()
      .then(data => {
          const payment_token = data.payment_token;
          const card_mask = data.card_mask;

          // console.log('payment_token', payment_token);
          // console.log('card_mask', card_mask);

          this.cartaoPaymentToken = payment_token
          // 4 Últimos Dígitos
          this.cartaoFinalCartao = card_mask.slice(-4)

          // Realizar Pagamento

          useJwt.assinaturaCadastrar({
            tokenPagamento: this.cartaoPaymentToken,
            bandeira: this.card.brand,
            finalCartao: this.cartaoFinalCartao,
            planoId: this.planoId,
          })
          .then(response => {

            const usuarioData = JSON.parse(localStorage.getItem('usuarioData'));
            usuarioData.assinaturaAtiva = true;
            localStorage.setItem('usuarioData', JSON.stringify(usuarioData));

            this.$swal({
              title: 'Processando pagamento!',
              text: 'Seu pagamento está sendo processado. Você será notificado quando o status for atualizado.',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.isConfirmed) {
                this.$router.push({ name: 'financeiro-saldo' })
              } else {
                this.$router.push({ name: 'financeiro-saldo' })
              }
            })

          })
          .catch(error => {

            this.$swal({
              title: 'Erro!',
              text: 'Ocorreu um erro. '+error.response.data.message,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })

          })
          .finally(() => {
            this.respostaApi = false
          })

      }).catch(err => {

        this.$swal({
            title: 'Ops! Aconteceu um erro.',
            text: 'Confira os dados do cartão. Tente novamente.',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })

          // console.log('Código: ', err.code);
          // console.log('Nome: ', err.error);
          // console.log('Mensagem: ', err.error_description);
      });

      // this.consulta.getPaymentToken(this.card, (error, response) => {
      //   if (error) {
      //     console.error(error)

      //     this.$swal({
      //       title: 'Ops! Aconteceu um erro.',
      //       text: 'Confira os dados do cartão. Tente novamente.',
      //       icon: 'error',
      //       customClass: {
      //         confirmButton: 'btn btn-primary',
      //       },
      //       buttonsStyling: false,
      //     })

      //   } else {

      //     //console.log(response.data.payment_token);

      //     this.cartaoPaymentToken = response.data.payment_token
      //     // 4 Últimos Dígitos
      //     this.cartaoFinalCartao = response.data.card_mask.slice(-4)


      //     // Realizar Pagamento

      //     useJwt.assinaturaCadastrar({
      //       tokenPagamento: this.cartaoPaymentToken,
      //       bandeira: this.card.brand,
      //       finalCartao: this.cartaoFinalCartao,
      //       planoId: this.planoId,
      //     })
      //     .then(response => {

      //       const usuarioData = JSON.parse(localStorage.getItem('usuarioData'));
      //       usuarioData.assinaturaAtiva = true;
      //       localStorage.setItem('usuarioData', JSON.stringify(usuarioData));

      //       this.$swal({
      //         title: 'Processando pagamento!',
      //         text: 'Seu pagamento está sendo processado. Você será notificado quando o status for atualizado.',
      //         icon: 'success',
      //         customClass: {
      //           confirmButton: 'btn btn-primary',
      //         },
      //         buttonsStyling: false,
      //       }).then(result => {
      //         if (result.isConfirmed) {
      //           this.$router.push({ name: 'financeiro-saldo' })
      //         } else {
      //           this.$router.push({ name: 'financeiro-saldo' })
      //         }
      //       })

      //     })
      //     .catch(error => {

      //       this.$swal({
      //         title: 'Erro!',
      //         text: 'Ocorreu um erro. '+error.response.data.message,
      //         icon: 'error',
      //         customClass: {
      //           confirmButton: 'btn btn-primary',
      //         },
      //         buttonsStyling: false,
      //       })

      //     })
      //     .finally(() => {
      //       this.respostaApi = false
      //     })


      //   }

      //   this.respostaApi = false

      // });


    },

  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
//@import '@fracto/vue-credit-card/';

.card.selecionarPagamento:hover {
  box-shadow: 0 0 5px 1px rgb(254 152 86 / 40%) !important;
  border-color: #ff7300 !important;
}


</style>
